
  .listing-enter {
    opacity: 0;
    max-height: 0px;
    transform: translateY(-);
    transition: all .3s;  

  }
  .listing-enter-active {
    max-height: 300px;
    opacity: 1;
  }
  .listing-exit {
    max-height: 300px;
    opacity: 1;

  }
  .listing-exit-active {
    max-height: 0px;
    opacity: 0;
    transition: opacity .25s;  
    transition: max-height .3s;  
  }


.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

span.activate-user {
  padding: 12px 30px;
  background-color: #4caf50;
  border-radius: 30px;
  color: #000;
  cursor: pointer;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%);

}
span.activate-user:hover {
  padding: 12px 30px;
  background-color: #4caf50;
  border-radius: 30px;
  color: #000;
  cursor: pointer;


}
.add-sous-category{
  cursor: pointer;
  border:1px solid #4caf50;
  padding: 12px 30px;
  border-radius: 30px;
  transition: all 0.3s
}
.add-sous-category svg{
  color: #5BC236;
  transition: all 0.3s
}
.add-sous-category:hover{
  background-color: #4caf50;
  color: #000
}
.add-sous-category:hover svg{
  color: #000
}


.indent_title_in {
  position: relative;
  padding-left: 60px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .indent_title_in {
    /* For less than 767px */
    padding-left: 0;
  }
}
.indent_title_in svg {
  font-size: 40px;
  position: absolute;
  left: 0;
  color: #9c27b0;
  top: 0;
}
@media (max-width: 767px) {
  .indent_title_in svg {
    /* For less than 767px */
    position: static;
  }
}
.indent_title_in h3 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 21px;
}
.indent_title_in p {
  color: #999;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-size: 0.875rem;
}

.wrapper_indent {
  padding-left: 60px;
}
.wrapper_indent p{
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .wrapper_indent {
    /* For less than 767px */
    padding-left: 0;
  }
}

.MuiTimelineItem-missingOppositeContent:before {
  flex: 0 1 !important;
  padding: 0 !important;
}


.btn-secondary {
  box-shadow: 0 2px 2px 0 rgb(5 150 222 / 14%), 0 3px 1px -2px rgb(5 150 222 / 20%), 0 1px 5px 0 rgb(5 150 222 / 12%);
  background-color: #0596de;
  color: #FFF;
    border: none;
    cursor: pointer;
    margin: .3125rem 1px;
    padding: 12px 30px;
    position: relative;
    font-size: 16px;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    will-change: box-shadow, transform;
    border-radius: 10px;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
}

.btn-secondary:hover{
  background-color: #0596de;

}